import React from 'react'
import TextImageBlock from './TextImageBlock'
import MessageForm from '@components/MessageForm'
import QuestionSection from '@components/QuestionSection'

export default function TemplateContent({
    content
}) {
    return (
        <div className='m-auto w-[1024px] smsize:w-full'>
            <div className='p-10 flex items-center justify-center gap-5 flex-col'>
                {content?.title &&
                    <h1 className='font-bold text-[24px]'>{content?.title}</h1>
                }
                {content?.short_description &&
                    // <div>{content?.short_description}</div>
                    <div className='ql-editor' dangerouslySetInnerHTML={{ __html: content.short_description }}></div>
                }
            </div>
            {content?.size_price &&
                <div className='my-5 smsize:max-w-full smsize:p-2 max-w-[500px] m-auto flex flex-col gap-3'>
                    <h2 className='font-bold text-[20px] text-center'>{content?.size_price_title}</h2>
                    <table className='my-table'>
                        <thead>
                            <tr>
                                {content?.size_price?.columns?.map((item, index) => (
                                    <th key={index}>{item}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {content?.size_price?.data?.map((item, index1) => (
                                <tr key={index1}>
                                    {item.map((d, index2) => (
                                        <td key={index2}>{d}</td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            }
            {content?.text_image_1 &&
                <TextImageBlock block={content?.text_image_1} />
            }
            {content?.text_image_2 &&
                <TextImageBlock block={content?.text_image_2} />
            }
            {content?.text_image_3 &&
                <TextImageBlock block={content?.text_image_3} />
            }
            {content?.content &&
                <div className='w-full my-5 p-5 smsize:p-2'>
                    <div className='ql-editor' dangerouslySetInnerHTML={{ __html: content?.content }}></div>
                </div>
            }
            {content?.add_faq &&
                <QuestionSection ids={content?.faq_ids} />
            }
            {content?.add_message_form &&
                <MessageForm title='Contact Us' />
            }
        </div>
    )
}
