import './App.css'
import React, { useEffect, Suspense, lazy } from 'react'
import Loading from './components/Loading'
import ScrollToTop from './components/ScrollToTop'
import Navbar from './components/Navbar'
import Footer from './components/Footer'
import Home from './pages/home/Home'
import About from './pages/about/About'
import Partner from './pages/about/Partner'
import Contact from './pages/contact/Contact'
import ShoppingCart from './pages/shoppingCart/ShoppingCart'
import New from './pages/new/New'
import Product from './pages/product/Product'
// import Service from './pages/service/Service'
import ServiceNew from './pages/service/ServiceNew'
import PolicyDetail from './pages/policy/PolicyDetail'
import Faq from './pages/faq/Faq'
import Login from './pages/user/Login'
import Setting from './pages/user/Setting'
import Wheels from './pages/wheels/Wheels'
import WheelBalanceWeights from './pages/wheels/WheelBalanceWeights'
import Register from './pages/user/Register'
import BackToTopButton from './components/BackToTopButton'
import { Routes, Route } from 'react-router-dom'
import SearchByVehicle from './pages/home/SearchByVehicle'
import NotFound from './components/NotFound'

import {
  tokenlogin,
  // getContentList,
  getProductTypeList,
  getNewTypeList,
  settingGet
  // getQuestionList,
} from './services/api.service'
// import 'react-confirm-alert/src/react-confirm-alert.css'

import { useDispatch } from 'react-redux'
import { initNewsTypeList, initProductsTypeList, setIsMobile, setSettings } from './reducers/contentReducer'
import { setUser, logout } from './reducers/userReducer'

/*
import About from './pages/about/About'
import Partner from './pages/about/Partner'
import Contact from './pages/contact/Contact'
import ShoppingCart from './pages/shoppingCart/ShoppingCart'
import New from './pages/new/New'
import Product from './pages/product/Product'
import Service from './pages/service/Service'
import PolicyDetail from './pages/policy/PolicyDetail'
import Faq from './pages/faq/Faq'
import Login from './pages/user/Login'
import Setting from './pages/user/Setting'
import Wheels from './pages/wheels/Wheels'
import WheelBalanceWeights from './pages/wheels/WheelBalanceWeights'
import Register from './pages/user/Register'
*/
// const About = lazy(() => import(/* webpackChunkName: "About" */ './pages/about/About'))
// const Partner = lazy(() => import(/* webpackChunkName: "Partner" */ './pages/about/Partner'))
// const Contact = lazy(() => import(/* webpackChunkName: "Contact" */ './pages/contact/Contact'))
// const ShoppingCart = lazy(() => import(/* webpackChunkName: "ShoppingCart" */ './pages/shoppingCart/ShoppingCart'))
// const New = lazy(() => import(/* webpackChunkName: "New" */ './pages/new/New'))
// const Product = lazy(() => import(/* webpackChunkName: "Product" */ './pages/product/Product'))
// const Service = lazy(() => import(/* webpackChunkName: "Service" */ './pages/service/Service'))
// const PolicyDetail = lazy(() => import(/* webpackChunkName: "PolicyDetail" */ './pages/policy/PolicyDetail'))
// const Faq = lazy(() => import(/* webpackChunkName: "Faq" */ './pages/faq/Faq'))
// const Login = lazy(() => import(/* webpackChunkName: "Login" */ './pages/user/Login'))
// const Setting = lazy(() => import(/* webpackChunkName: "Setting" */ './pages/user/Setting'))
// const Wheels = lazy(() => import(/* webpackChunkName: "Wheels" */ './pages/wheels/Wheels'))
// const WheelBalanceWeights = lazy(() => import(/* webpackChunkName: "WheelBalanceWeights" */ './pages/wheels/WheelBalanceWeights'))
// const Register = lazy(() => import(/* webpackChunkName: "Register" */ './pages/user/Register'))

function App() {
  // state for loading
  const [isLoaded, setIsLoaded] = React.useState(false)
  // get dispatch
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setIsMobile(window.innerWidth < 600))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // loading data
  useEffect(() => {
    const loadingData = async () => {
      try {
        // const contentRes = await getContentList()
        // dispatch(initContentList(contentRes.data))

        const newsTypeRes = await getNewTypeList()
        dispatch(initNewsTypeList(newsTypeRes.data))

        const productsTypeRes = await getProductTypeList()
        dispatch(initProductsTypeList(productsTypeRes.data))

        const settingsRes = await settingGet()
        dispatch(setSettings(settingsRes.data))

        setIsLoaded(true)
      } catch (error) {
        console.error('loadingData failed: ', error.data?.message)
      }
    }
    loadingData()
    window.addEventListener('resize', () => {
      dispatch(setIsMobile(window.innerWidth < 600))
    })
    return () => {
      window.removeEventListener('resize', () => {
        dispatch(setIsMobile(window.innerWidth < 600))
      })
    }
  }, [dispatch])

  useEffect(() => {

    const handleTokenLogin = async (userinfo) => {
      try {
        const result = await tokenlogin(userinfo)
        // console.log('handleTokenLogin', result)
        dispatch(setUser(result))
      } catch (error) {
        dispatch(logout())
        console.log('handleTokenLogin', error.data.message)
      }
    }

    let userinfo = localStorage.getItem('userinfo')
    if (userinfo) {
      userinfo = JSON.parse(userinfo)
      // console.log('getUserinfo', userinfo)
      handleTokenLogin(userinfo)
    }
  }, [dispatch])


  // if not loaded, show loading
  if (!isLoaded) return <Loading />

  return (
    <>
      <ScrollToTop />
      <BackToTopButton />
      <Navbar />
      <div className='page-content'>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/search-by-vehicle" element={<SearchByVehicle />} />
          <Route path="/about" element={<About />} />
          <Route path="/partner-brands" element={<Partner />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/articles/:title?" element={<New />} />
          <Route path="/tires/:type?/:id?/:title?/:size?/:option?" element={<Product />} />
          {/* <Route path="/product-detail/:id" element={<ProductDetail />} /> */}
          <Route path="/services/:id?" element={<ServiceNew />} />
          {/* <Route path="/servicesNew" element={<ServiceNew />} /> */}
          <Route path="/wheels" element={<Wheels />} />
          <Route path="/wheel-balance" element={<WheelBalanceWeights />} />
          <Route path="/policy/:id?" element={<PolicyDetail />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/setting" element={<Setting />} />
          <Route path="/shopping-cart" element={<ShoppingCart />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
      <Suspense fallback={<div>Loading...</div>}>
        <Footer key='footer' />
      </Suspense>
    </>
  )
}

export default App
