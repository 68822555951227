import React from 'react'

export default function TextImageBlock({
    block
}) {
    return (
        <div className={`my-5 p-5 smsize:p-2 m-auto w-[1024px] smsize:w-full smsize:flex-col flex justify-center items-center gap-5 ${block.is_image_on_right ? 'flex-row-reverse' : 'flex-row'}`}>
            {block?.image &&
                <div className='w-1/2 smsize:w-full flex items-center justify-center p-5'>
                    <img className='max-w-[400px] w-full' src={block?.image} alt={block?.imageAlt} />
                </div>}
            {block?.text &&
                <div className=' w-1/2 smsize:w-full flex flex-col gap-3'>
                    <h2 className='font-bold text-[20px]'>{block?.title || 'Title'}</h2>
                    {/* <p>{block?.text}</p> */}
                    <div className='ql-editor' dangerouslySetInnerHTML={{ __html: block?.text }}></div>
                </div>}
        </div>
    )
}
